import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({path}) => (
  <Layout path={path}>
    <Seo title="404: Seite nicht gefunden" />
    <h1>Seite nicht gefunden...</h1>
  </Layout>
)

export default NotFoundPage
